<template>
  <div class="loginWrap">
    <div class="loginForm">
      <div v-if="isLogin" class="content">
        <div class="formNavbar">
          <div
            class="passNavbar"
            :class="ActivateIndex === 1 ? 'activate' : ''"
            @click="pitchOn(1)"
          >
            密码登录
          </div>
          <div class="line"></div>
          <div
            class="verificationNavbar"
            :class="ActivateIndex === 2 ? 'activate' : ''"
            @click="pitchOn(2)"
          >
            验证码登录
          </div>
        </div>
        <div v-if="ActivateIndex === 1" class="passForm">
          <el-form ref="passForm" :model="passForm" :rules="passRules">
            <!-- <el-form-item key="officeCode" prop="officeCode">
              <el-input
                v-model="passForm.officeCode"
                placeholder="请输入店铺编码"
              ></el-input>
            </el-form-item> -->
            <el-form-item key="loginName" prop="loginName">
              <el-input
                v-model="passForm.loginName"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                placeholder="请输入用户名/手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item key="password" prop="password">
              <el-input
                v-model="passForm.password"
                placeholder="请输入密码"
                show-password
              ></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <SmartCaptcha
                ref="captcha"
                scene="ic_login"
                width="100%"
                @success="passwordLogin"
              />
            </el-form-item> -->
            <el-row>
              <el-col :span="12">
                <el-form-item prop="code">
                  <el-input
                    ref="code"
                    v-model="passForm.code"
                    placeholder="请输入验证码"
                    name="code"
                    type="text"
                    tabindex="4"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12" style="text-align: right">
                <img :src="validateImg" alt="" @click="getValidateCode" />
              </el-col>
            </el-row>
          </el-form>
          <p class="Forge" @click="retrievCode">忘记密码</p>
          <div class="buttonWrap">
            <div class="loginButton" @click="Login('passForm')">登 录</div>
          </div>
          <el-button type="text" @click="goRegister">立即注册</el-button>
          <div>
            <el-checkbox v-model="isAgree"></el-checkbox>我已经阅读并同意
            <span style="color: #177ee5; cursor: pointer" @click="toUserXieyi()"
              >《用户协议》</span
            >和<span
              style="color: #177ee5; cursor: pointer"
              @click="toSecretXieyi()"
              >《隐私政策》</span
            >
          </div>
        </div>
        <div v-else class="verificationForm">
          <div v-if="!selectCode">
            <el-form
              :model="verificationForm"
              :rules="verificationRules"
              ref="verificationForm"
            >
              <el-form-item
                key="loginName"
                prop="loginName"
                style="position: relative"
              >
                <el-input
                  v-model="verificationForm.loginName"
                  placeholder="请输入手机号码"
                ></el-input>
                <el-button
                  class="sendCode"
                  style="position: absolute; right: 3%"
                  type="text"
                  :disabled="!verificationflag"
                  @click="sendCode"
                  >{{ verificationText }}</el-button
                >
              </el-form-item>
              <!-- <el-form-item v-if="isShowCaptcha">
                <SmartCaptcha
                  ref="captcha"
                  scene="ic_login"
                  width="100%"
                  @success="smartCaptchaSuccess"
                />
              </el-form-item> -->
              <el-form-item key="loginCode" prop="loginCode">
                <el-input
                  v-model="verificationForm.loginCode"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="buttonWrap">
              <div
                class="loginButton"
                @click="verificationLogin('verificationForm')"
              >
                登 录
              </div>
            </div>
            <el-button type="text" @click="goRegister">立即注册</el-button>
            <div>
              <el-checkbox v-model="isAgree"></el-checkbox>我已经阅读并同意
              <span
                style="color: #177ee5; cursor: pointer"
                @click="toUserXieyi()"
                >《用户协议》</span
              >和<span
                style="color: #177ee5; cursor: pointer"
                @click="toSecretXieyi()"
                >《隐私政策》</span
              >
            </div>
          </div>
          <!-- 下拉选择店铺 -->
          <div v-else>
            <el-form class="organizationForm">
              <div style="text-align: left; margin-bottom: 20px">
                <el-button type="text" @click="selectCode = false"
                  >返回</el-button
                >
              </div>
              <el-form-item>
                <el-select
                  style="width: 100%"
                  v-model="currentOrgId"
                  placeholder="请选择店铺"
                >
                  <el-option
                    v-for="item in organization"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div class="buttonWrap">
                  <div class="loginButton" @click="officeLogin">
                    立 即 登 录
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div v-else class="content">
        <div>
          <div
            style="
              margin-top: 120px;
              margin-bottom: 20px;
              width: 100%;
              text-align: right;
              color: #4c8df2;
            "
            @click="toLogin"
          >
            已有账号，去登录
            <!-- <i class="el-icon-edit"></i> -->
            <i class="el-icon-right"></i>
          </div>
          <el-form
            :model="verificationForm"
            :rules="verificationRules"
            ref="verificationForm"
          >
            <el-form-item
              key="loginName"
              prop="loginName"
              style="position: relative"
            >
              <el-input
                v-model="verificationForm.loginName"
                placeholder="请输入手机号码"
              ></el-input>
              <el-button
                class="sendCode"
                style="position: absolute; right: 3%"
                type="text"
                :disabled="!verificationflag"
                @click="sendCode"
                >{{ verificationText }}</el-button
              >
            </el-form-item>
            <!-- <el-form-item v-if="isShowCaptcha">
                <SmartCaptcha
                  ref="captcha"
                  scene="ic_login"
                  width="100%"
                  @success="smartCaptchaSuccess"
                />
              </el-form-item> -->
            <el-form-item key="loginCode" prop="loginCode">
              <el-input
                v-model="verificationForm.loginCode"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="buttonWrap">
            <div class="loginButton" @click="registerLogin('verificationForm')">
              注 册
            </div>
          </div>
          <div>
            <el-checkbox v-model="isAgree"></el-checkbox>我已经阅读并同意
            <span style="color: #177ee5; cursor: pointer" @click="toUserXieyi()"
              >《用户协议》</span
            >和<span
              style="color: #177ee5; cursor: pointer"
              @click="toSecretXieyi()"
              >《隐私政策》</span
            >
          </div>
        </div>
      </div>
    </div>
    <miaFooter />
  </div>
</template>
<script>
import { loginPassWord, GetVerificationCode, registerShop } from "@/api/common";
import SmartCaptcha from "@/components/smartCaptcha/index.vue";
import { getValidateCode } from "@/api/system";
import { mapMutations } from "vuex";
import md5 from "md5";
import { Base64 } from "js-base64";
import { Message } from "element-ui";
import moment from "moment";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
          this.verificationflag = true;
        } else {
          callback(new Error("请输入正确的手机号"));
          this.verificationflag = false;
          this.isShowCaptcha = false;
        }
      } else {
        callback(new Error("请填写手机号码"));
        this.verificationflag = false;
        this.isShowCaptcha = false;
      }
    };
    return {
      // 密码登录
      passForm: {
        // officeCode: "",
        loginName: "",
        password: "",
        code: "",
        token: "",
      },
      isLogin: true,
      validateImg: "",
      passRules: {
        // officeCode: [
        //   { required: true, message: "请输入店铺编码", trigger: "blur" },
        //   { min: 1, max: 5, message: "长度在 1 到 5 个字符", trigger: "blur" },
        // ],
        loginName: [
          { required: true, message: "请输入用户名或手机号", trigger: "blur" },
          {
            min: 5,
            max: 11,
            message: "用户名或手机号长度在 5 到 11 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      isAgree: false,
      // 验证码登录
      verificationForm: {
        loginName: "",
        loginCode: "",
        // loginToken: "",
      },
      verificationRules: {
        loginName: [{ required: true, validator: validatePhone }],
        loginCode: [
          { required: true, message: "短信验证码不能为空", trigger: "blur" },
          { min: 1, max: 6, message: "短信验证码格式错误", trigger: "blur" },
        ],
      },
      verificationText: "发送验证码",
      verificationflag: false,
      time: 60,
      // 计时器
      timepiece: null,
      ActivateIndex: 1,
      isShowCaptcha: false,
      // 人机验证
      valiteCodeData: null,
      icCap: null,
      // 下拉选择
      selectCode: false,
      // 店铺编码
      organization: [],
      currentOrgId: null,
      pollingST: null,
      number: 60,
    };
  },
  components: {
    SmartCaptcha,
  },
  created() {
    this.getValidateCode();
    const item = JSON.parse(sessionStorage.getItem("registerItem"));
    if (item) {
      // this.passForm.officeCode = item.code || item.officeCode;
      this.passForm.loginName = item.loginName || "";
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("registerItem");
  },
  watch: {
    time(val) {
      if (val === 0) {
        this.resetTime();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timepiece);
  },
  methods: {
    ...mapMutations("common", ["updateMainTabsActiveName"]),
    ...mapMutations("user", [
      "updataToken",
      "updateId",
      "updateName",
      "updateuserName",
      "updateOfficeId",
      "updateOfficeName",
      "updateOfficeType",
    ]),
    // 切换登录方式
    pitchOn(index) {
      this.ActivateIndex = index;
      if (index === 1) {
        setTimeout(() => {
          this.$refs["passForm"].resetFields();
        }, 30);
      } else {
        setTimeout(() => {
          this.$refs["verificationForm"].resetFields();
          if (this.verificationForm.loginName !== "") {
            this.$refs["verificationForm"].validateField("loginName");
          } else {
            this.verificationflag = false;
          }
        }, 30);
      }
      this.valiteCodeData = null;
      this.icCap = null;
    },
    getValidateCode() {
      getValidateCode().then((res) => {
        if (res.code === 1) {
          this.validateImg = "data:image/jpeg;base64," + res.data.img;
          this.passForm.token = res.data.uuid;
        }
      });
    },
    // 人机验证
    passwordLogin(data) {
      this.valiteCodeData = data.data;
      this.icCap = data.control;
    },
    // 密码登录
    Login(formName) {
      if (!this.isAgree) {
        return this.$message.warning("请阅读并勾选底部协议");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (!(this.valiteCodeData && this.valiteCodeData.token)) {
          //   return Message({
          //     message: "请拖动滑块进行验证",
          //     type: "error",
          //     duration: 1000,
          //   });
          // }
          let dataForm = JSON.parse(JSON.stringify(this[formName]));
          dataForm.password = md5(dataForm.password);
          loginPassWord(dataForm).then((res) => {
            if (res.data.code === 1 && res.status === 200) {
              const { data } = res.data;
              this.updataToken(data.token);
              this.Tolink(data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 验证码登录
    verificationLogin(formName) {
      if (!this.isAgree) {
        return this.$message.warning("请阅读并勾选底部协议");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          loginPassWord(this.verificationForm).then((res) => {
            if (res.data && res.status === 200) {
              const { data, code, msg } = res.data;
              if (code === 1) {
                // 正常一个店铺
                this.updataToken(data.token);
                this.Tolink(data);
              } else if (code === 3) {
                // 为多个店铺
                this.selectCode = true;
                this.organization = data;
              } else if (code === -1) {
                this.$message.error(msg);
              }
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    registerLogin(formName) {
      if (!this.isAgree) {
        return this.$message.warning("请阅读并勾选底部协议");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          registerShop({
            contact: this.verificationForm.loginName,
            tokenCode: this.verificationForm.loginCode,
          }).then((res) => {
            const { data, code, msg } = res;
            if (code === 1) {
              // 正常一个店铺
              let result;
              result = JSON.parse(JSON.stringify(this[formName]));
              result.password = md5("a1" + result.loginName);
              loginPassWord(result).then((res) => {
                if (res.data.code === 1 && res.status === 200) {
                  const { data } = res.data;
                  this.updataToken(data.token);
                  this.Tolink(data);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            } else if (code === -1) {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 跳转页面存值
    Tolink(data) {
      const exp = JSON.parse(Base64.decode(data.token.split(".")[1])).exp;
      sessionStorage.setItem(
        "expirationTime",
        moment(moment.unix(exp)).valueOf()
      );
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("menuList", JSON.stringify(data["menus"]));
      sessionStorage.setItem(
        "permissions",
        JSON.stringify(data["permissions"])
      );
      const {
        name,
        officeId,
        officeName,
        officeType,
        userId,
        dueDate,
        payVersion,
        userName,
        userCreateDate,
        contact,
      } = JSON.parse(
        JSON.parse(Base64.decode(data["token"].split(".")[1])).sub
      );
      this.updateOfficeId(officeId);
      this.updateOfficeName(officeName);
      this.updateId(userId);
      this.updateName(name);
      this.updateuserName(userName);
      this.updateOfficeType(officeType);
      sessionStorage.setItem("userName", userName);
      sessionStorage.setItem("officeName", officeName);
      sessionStorage.setItem("Name", name);
      sessionStorage.setItem("contact", contact);
      sessionStorage.setItem("createDate", userCreateDate);
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("dueDate", dueDate);
      sessionStorage.setItem("payVersion", payVersion);
      this.$message.success("登录成功");
      this.$router.push({ name: "home" });
      this.updateMainTabsActiveName("");
    },
    // 注册
    goRegister() {
      // this.$router.push({ name: "register" });
      this.isLogin = false;
    },
    toUserXieyi() {
      window.open("https://b.yinongyun.cn/yny_xieyi.html");
    },
    toSecretXieyi() {
      window.open("https://b.yinongyun.cn/yny_secret.html");
    },
    toLogin() {
      this.isLogin = true;
    },
    changeTime() {
      this.time -= 1;
      this.verificationText = this.time + "s后可重新发送";
    },
    // 发送验证码
    sendCode() {
      const form = { mobile: this.verificationForm.loginName, type: 1 };
      GetVerificationCode(form).then((res) => {
        if (res.code === 1) {
          // this.verificationForm.loginToken = res.data;
          // 发送成功
          this.$message.success("验证码发送成功，请注意查收");
          this.verificationflag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.$message.error(res.msg);
          this.resetTime();
        }
      });
    },
    smartCaptchaSuccess(data) {
      this.valiteCodeData = data.data;
      this.icCap = data.control;
      this.verificationflag = false;
      // 验证成功 发生验证码
      const form = { mobile: this.verificationForm.loginName, type: 1 };
      const result = { ...form, ...this.valiteCodeData };
      GetVerificationCode(result).then((res) => {
        if (res.code === 1) {
          // this.verificationForm.loginToken = res.data;
          // 发送成功
          this.$message.success("验证码发送成功，请注意查收");
          this.verificationflag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.$message.error(res.msg);
          this.resetTime();
        }
      });
      this.isShowCaptcha = false;
    },
    resetTime() {
      clearInterval(this.timepiece);
      this.verificationText = "重新发送";
      this.time = 60;
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (reg.test(this.verificationForm.loginName)) {
        this.verificationflag = true;
      } else {
        this.verificationflag = false;
      }
    },
    // 选择店铺后登录
    officeLogin() {
      if (this.currentOrgId) {
        this.verificationForm.officeId = this.currentOrgId;
        loginPassWord(this.verificationForm).then((res) => {
          if (res.data && res.status === 200) {
            const { data, code, msg } = res.data;
            if (code === 1) {
              // 正常一个店铺
              this.updataToken(data.token);
              this.Tolink(data);
            }
          } else {
            this.$message.error(msg);
          }
        });
      } else {
        this.$message.error("请选择店铺");
      }
    },
    // 找回店铺编码/密码
    retrievCode() {
      this.$router.push({ name: "retrieve" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("../../assets/scss/login.scss");
.loginForm {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  .content {
    display: inline-block;
    width: 90%;
    height: 90%;
    .formNavbar {
      width: 100%;
      height: 5vh;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px #f1f1f1 solid;
      .line {
        width: 1px;
        height: 16px;
        background: #f1f5ff;
      }
      .passNavbar,
      .verificationNavbar {
        width: 48%;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: all 0.1s linear;
        font-size: 18px;
        &:hover {
          cursor: pointer;
        }
      }
      .activate {
        font-weight: bold;
        color: #4c8df2;
        border-bottom: 1px #4c8df2 solid;
      }
    }
    .passForm,
    .verificationForm,
    .organizationForm {
      width: 90%;
      height: 75%;
      padding: 5%;
      margin-top: 20px;
      .Forge {
        width: 100%;
        text-align: right;
        cursor: pointer;
      }
    }
    .buttonWrap {
      width: 100%;
      height: 5vh;
      margin: 15px 0;
      .loginButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #4c8df2;
        border-radius: 4px;
        color: #ffffff;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .organizationForm {
      margin-top: 0px;
    }
  }
}
// 输入框内默认提示的字体
/deep/ input::-webkit-input-placeholder {
  font-family: Noto Sans PingFang;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #b9b9b9;
}
.mia-footer {
  position: fixed;
  bottom: 0%;
}
</style>
